<template>
  <div>
    <b-container fluid class="p-1">
          <div style="text-align: center">
        <h6>
          腾讯官方认证 - 福利官 <b-avatar size="sm" :src="avatar" />{{
          nickname
        }} 为你服务
        </h6>
      </div>
      <span style="text-align: center"
        ></span
      >
      <b-row>
        <b-col>
          <b-img-lazy thumbnail fluid :src="qrcode" alt="Image 2')" />
        </b-col>
      </b-row>
      </br>
      <div style="text-align: center">
        <h4>
          <b-badge variant="light-success">
            按住上方二维码添加客服微信
          </b-badge>
        </h4>
      </div>
    </b-container>
  </div>
</template>
<script>
import {
  BImg,
  BBadge,
  BContainer,
  BRow,
  BCol,
  BImgLazy,
  BAvatar,
} from "bootstrap-vue";

import useJwt from "@/auth/jwt/useJwt";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BBadge,
    BImg,
    BContainer,
    BRow,
    BCol,
    BImgLazy,
    BAvatar,
  },
  data() {
    return {
      code: "",
      cid: "",
      wrong: "",
      did: "",
      qrcode: "",
      nickname: "",
      avatar: "",
      channl: "",
    };
  },
  mounted() {
    document.title = "微信认证";
    this.code = this.$route.query.code;
    this.rid = this.$route.query.rid; //推广者微信
    this.cid = this.$route.query.cid;
    this.did = this.$route.query.did;
    this.type = this.$route.query.type;
    this.channl = this.$route.query.channl;
    useJwt
      .querywechatqrcode({
        cid: this.cid,
        did: this.did,
        rid: this.rid,
        type: this.type,
        code: this.code,
        channl: this.channl,
      })
      .then((res) => {
        console.log(" this.avatar", JSON.stringify(res));
        if (res.data.code == "0") {
          this.qrcode = res.data.data.qrcode;
          this.nickname = res.data.data.wechatnick;
          this.avatar = res.data.data.avatar;
        }
        if (res.data.code == "1") {
          var wechatid = res.data.data.wechatid;
          this.$router.push({
            name: "promoter",
            query: { cid: this.cid, wechatid: wechatid, rid: this.rid },
          });
        }
        if (res.data.code == "2") {
          this.$toast({
            component: ToastificationContent,
            position: "bottom-center",
            props: {
              title: `${res.data.data}`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `五秒后关闭窗口`,
            },
          });
          setTimeout(() => {
            WeixinJSBridge.call("closeWindow");
          }, 5000);
        }
        if (res.data.code == "3") {
          var url = res.data.data;
          window.location.href = url;
        }
      });
  },
};
</script>